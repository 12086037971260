<template>
  <div class="main van-clearfix">
    <!-- <div 
      style="opacity: .6;position:fixed;bottom: 120px;right: 20px;border-radius: 30px;padding: 20px 12px;background-color: #1989fa;color: #fff;z-index: 999;"
      @click="()=>{this.$router.push('/info/wx996229f81e6a88f8')}"
    >
      back
    </div> -->
    <div class="divfrist">
      <van-row type="flex" justify="space-between">
        <van-col span="12" class="vancolfrist-Month"
          ><span style="font-size: 4rem; text-align: left">{{ nowMonth }}</span
          >月</van-col
        >
        <van-col span="12" class="vancoltow">
          <span style="font-size: 1.5rem">{{ msisdn }}</span>
          <br />
          <!-- （{{ operator }}) -->
        </van-col
        >
      </van-row>
      <van-row type="flex" justify="space-between">
        <van-col span="12" class="vancolfrist"
          >支出<span style="font-size: 1.3rem">￥{{ income }}</span></van-col
        >
        <van-col span="12" class="vancoltow"
          >收入<span style="font-size: 1.3rem">￥{{ expend }}</span></van-col
        >
      </van-row>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check="false"
    >
      <div v-for="(item, key) in recordList" :key="key" style="padding: 7px">
        <van-row style="padding: 10px; background-color: white; color: #000000">
          <van-col span="12">
            <span style="" v-for="dict in optTypes" :key="dict.dictValue">
              <span v-if="dict.dictValue == item.optType">
                {{ dict.dictLabel }}
              </span>
            </span>
          </van-col>
          <van-col span="12" style="text-align: right">
            {{ item.optMoney }}
          </van-col>
        </van-row>
        <van-row
          style="padding: 10px; background-color: white; font-size: 0.9rem"
        >
          <van-col span="12" style="text-align: left; color: gray">
            {{ item.createTime }}
          </van-col>
          <van-col span="12" style="text-align: right; color: #ffab00">
            账户余额：￥{{ item.balanceMoney }}
          </van-col>
        </van-row>
        <van-divider
          :style="{
            color: '#e1e1e1',
            borderColor: '#e1e1e1',
            margin: '0px',
            padding: '0 4px',
          }"
        />
      </div>
    </van-list>
  </div>
  <common-footer />
</template>

<script>
import { getDicts, selectBalanceRecord, selectRecord } from "@/api/card";
import commonFooter from "@/views/common/footer.vue";
import { parseTimeNo0 } from "@/utils/index";
export default {
  components: {
    commonFooter,
  },
  data() {
    return {
      msisdn: this.$store.getters.msisdn,
      recordList: [],
      total: 0,
      optTypes: [],
      loading: false,
      finished: false,
      flag: false,
      pageNum: 1,
      pageSize: 10,
      nowMonth: parseTimeNo0(new Date(), "{m}"),
      operator: "",
      income: 0,
      expend: 0,
    };
  },
  created() {
    this.getBalanceList();
    this.dicts();
    this.operator =
      this.$store.getters.corp === "1"
        ? "中国移动"
        : this.$store.getters.corp === "2"
        ? "中国联通"
        : "中国电信";
  },

  methods: {
    onLoad() {
      if (this.flag == false) {
        this.flag = true;
        this.loading = true;
        this.getBalanceList();
        this.loading = false;
        if (this.pageNum * this.pageSize >= this.total) {
          this.finished = true;
        }
      }
    },
    getBalanceList() {
      selectBalanceRecord(this.$store.getters.iccId).then((res) => {
        if (res != null) {
          this.recordList = res.rows;
          this.total = res.total;
          this.flag = false;
          this.pageNum++;
        }
      });
      selectRecord(this.$store.getters.iccId).then((res) => {
        if (res != null) {
          this.income = res.income;
          this.expend = res.expend;
        }
      });
    },
    dicts() {
      getDicts("balance_opt_type").then((response) => {
        this.optTypes = response;
      });
    },
  },
};
</script>

<style>
.divfrist {
  background-color: #3185ff;
  color: white;
  height: 120px;
}
.vancolfrist {
  text-align: left;
  padding: 10px;
}
.vancoltow {
  text-align: right;
  padding: 10px;
}
.vancolfrist-Month {
  text-align: left;
  padding: 10px;
  height: 70px;
}
</style>
